import { Theme, useMediaQuery } from "@mui/material";
import { useCallback, useMemo } from "react";
import DataGrid from "../../../shared/components/grid/DataGrid";
import { FundraisingFile } from "../../api/types/fundraisingTypes";
import { columnDefinitions, groupingColumnDefinition } from "./fundraisingDocumentsGridDataProvider";

interface Props {
  files: FundraisingFile[];
}

const FundraisingDocumentsGrid = ({ files }: Props) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const columnVisibilityModel = useMemo(
    () => ({
      name: false,
      section: false,
      size: !isSmallScreen,
    }),
    [isSmallScreen]
  );

  const isGroupExpandedByDefault = useCallback(() => true, []);

  return (
    <DataGrid<FundraisingFile>
      getRowId={(row) => row.fileId}
      columns={columnDefinitions}
      groupingColDef={groupingColumnDefinition}
      rows={files}
      rowHeight={40}
      columnHeaderHeight={isSmallScreen ? 0 : 36}
      hideFooter
      disableColumnResize
      disableColumnFilter
      disableColumnPinning
      disableColumnSelector
      disableColumnSorting
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      disableMultipleRowSelection
      initialState={{
        rowGrouping: {
          model: ["section"],
        },
      }}
      isGroupExpandedByDefault={isGroupExpandedByDefault}
      columnVisibilityModel={columnVisibilityModel}
      sx={{
        ".MuiDataGrid-columnHeaderTitle": { color: "text.secondary" },
      }}
      noScrollableContainer
    />
  );
};

export default FundraisingDocumentsGrid;
