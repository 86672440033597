import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { useCallback, useRef, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { sessionStorageService } from "../../../services/storage";

interface PdfViewerSettings {
  scale: number;
}

const PDF_VIEWER_SETTINGS_KEY = "pdf_viewer_settings";

const getPdfViewerSettings = () =>
  sessionStorageService.getItemWithFallback<PdfViewerSettings>(PDF_VIEWER_SETTINGS_KEY, { scale: 1 });

const savePdfViewerSettings = (settings: PdfViewerSettings) =>
  sessionStorageService.setItem(PDF_VIEWER_SETTINGS_KEY, settings);

export interface PdfViewerState {
  numPages: number;
  scale: number;
  showThumbnails: boolean;
  currentPageInputValue: string;
  currentPageNumber: number;
  documentProxy?: PDFDocumentProxy;
  isDocumentLoaded: boolean;
  loadingProgress: number;
}

const getInitialState = (): PdfViewerState => {
  const userSettings = getPdfViewerSettings();

  return {
    numPages: 0,
    currentPageNumber: 0,
    currentPageInputValue: "",
    showThumbnails: false,
    isDocumentLoaded: false,
    loadingProgress: 0,
    ...userSettings,
  };
};

const minScale = 0.2;
const maxScale = 2;
const scaleStep = 0.2;

// Actions

type StateAction = (state: PdfViewerState) => PdfViewerState;

const updateLoadingProgressAction =
  (loadingProgress: number): StateAction =>
  (state) => ({
    ...state,
    loadingProgress,
  });

const documentLoadAction =
  (documentProxy: PDFDocumentProxy): StateAction =>
  (state) => ({
    ...state,
    documentProxy,
    numPages: documentProxy.numPages,
    currentPageNumber: documentProxy.numPages > 0 ? 1 : 0,
    currentPageInputValue: documentProxy.numPages > 0 ? "1" : "",
    isDocumentLoaded: documentProxy.numPages > 0,
  });

const zoomInAction = (): StateAction => (state) => {
  const scale = Math.min(state.scale + scaleStep, maxScale);

  savePdfViewerSettings({ scale });

  return {
    ...state,
    scale,
  };
};

const zoomOutAction = (): StateAction => (state) => {
  const scale = Math.max(state.scale - scaleStep, minScale);

  savePdfViewerSettings({ scale });

  return {
    ...state,
    scale,
  };
};

const toggleThumbnailsAction = (): StateAction => (state) => ({
  ...state,
  showThumbnails: !state.showThumbnails,
});

const updateCurrentPageInputValueAction =
  (currentPageInputValue: string): StateAction =>
  (state) => ({
    ...state,
    currentPageInputValue,
  });

const updateCurrentPageNumberAction =
  (currentPageNumber: number): StateAction =>
  (state) => ({
    ...state,
    currentPageNumber,
    currentPageInputValue: currentPageNumber.toString(),
  });

// Hook

interface Size {
  width: number;
  height: number;
}

export const usePdfViewer = () => {
  const pageRefs = useRef<HTMLElement[] | null>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [viewerState, setViewerState] = useState(getInitialState());
  const [containerSize, setContainerSize] = useState<Size>();

  const onResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const [containerEntry] = entries;
      if (!containerEntry) {
        return;
      }

      if (
        containerEntry.contentRect.height !== containerSize?.height ||
        containerEntry.contentRect.width !== containerSize?.width
      ) {
        setContainerSize({ height: containerEntry.contentRect.height, width: containerEntry.contentRect.width });
      }
    },
    [containerSize?.height, containerSize?.width]
  );

  useResizeObserver(containerRef.current, {}, onResize);

  const handleScroll = useDebounce(() => {
    if (!containerRef.current || !pageRefs.current) {
      return;
    }

    const pageTops = pageRefs.current.map((pageRef) => pageRef.getBoundingClientRect().top);
    const containerTop = containerRef.current.getBoundingClientRect().top || 0;

    let firstVisiblePageTopIndex = pageTops.findIndex((top) => top > 0);
    const firstVisiblePageTop = pageTops[firstVisiblePageTopIndex];
    if (
      firstVisiblePageTop !== undefined &&
      containerSize?.height &&
      firstVisiblePageTop - containerTop > containerSize.height / 2
    ) {
      firstVisiblePageTopIndex--;
    }

    if (
      firstVisiblePageTopIndex >= 0 &&
      firstVisiblePageTopIndex < viewerState.numPages &&
      firstVisiblePageTopIndex !== viewerState.currentPageNumber - 1
    ) {
      setViewerState(updateCurrentPageNumberAction(firstVisiblePageTopIndex + 1));
    }
  }, 250);

  const navigateToPage = useCallback(
    (pageNumber: number) => {
      setViewerState(updateCurrentPageNumberAction(pageNumber));
      pageRefs.current?.[pageNumber - 1]?.scrollIntoView({ behavior: "auto", block: "nearest" });
    },
    [pageRefs]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const nextPageNumber =
        e.key === "ArrowLeft"
          ? viewerState.currentPageNumber - 1
          : e.key === "ArrowRight"
            ? viewerState.currentPageNumber + 1
            : undefined;

      if (nextPageNumber !== undefined && nextPageNumber >= 1 && nextPageNumber <= viewerState.numPages) {
        e.preventDefault();
        navigateToPage(nextPageNumber);
      }
    },
    [navigateToPage, viewerState.currentPageNumber, viewerState.numPages]
  );

  const toggleThumbnails = useCallback(() => {
    setViewerState(toggleThumbnailsAction());
  }, []);

  const updateCurrentPageInputValue = useCallback((value: string) => {
    setViewerState(updateCurrentPageInputValueAction(value));
  }, []);

  const handleDocumentLoaded = useCallback((documentProxy: PDFDocumentProxy) => {
    setViewerState(documentLoadAction(documentProxy));
  }, []);

  const updateLoadingProgress = useCallback((progress: number) => {
    setViewerState(updateLoadingProgressAction(progress));
  }, []);

  const zoomIn = useCallback(() => {
    setViewerState(zoomInAction());
  }, []);

  const zoomOut = useCallback(() => {
    setViewerState(zoomOutAction());
  }, []);

  const resetState = useCallback(() => {
    setViewerState(getInitialState());
  }, []);

  return {
    viewerState,
    containerRef,
    pageRefs,
    containerSize,
    handleScroll,
    handleKeyDown,
    navigateToPage,
    toggleThumbnails,
    updateCurrentPageInputValue,
    handleDocumentLoaded,
    updateLoadingProgress,
    zoomIn,
    zoomOut,
    resetState,
  };
};
